import Vue from "vue";
import Vuex from "vuex";
import http from "../http-common";
import subscriptions from "./modules/subscriptions"
import suppliers from "./modules/suppliers"
import contracts from "./modules/contracts"
import packages from "./modules/packages"
import services from "./modules/services"
import organisations from "./modules/organisations"
import users from "./modules/users"
import customers from "./modules/customers"
import loyaltolocal from "./modules/loyaltolocal"
import thirdparty from "./modules/thirdparty";
import createPersistedState from "vuex-persistedstate";
import registration from "./modules/registration";
import auth from "./modules/authStore";
import countries from "./modules/countryStore";
import foodcredit from "./modules/foodcreditStore";

Vue.use(Vuex);




export default new Vuex.Store({

    state: {
        token : localStorage.getItem('token') |'',
        status:'',
        processing:1,
        messages:[],
        rule:{},
        user:{},
        site:{},
        rules:[],
        countries:[],
        cities:[],
        sites:[],
        districts:[],
        actionName:"",
        userId : null,
        timeout: 30000,
        menu_links: [],
        page_header_html: null,
        default_country: "BW"
    },
    mutations: {

        AUTH_REQUEST: (state) => {
            state.status = 'loading'
        },
        AUTH_SUCCESS: (state, token) => {
            state.status = 'success';
            state.token = token;
        },
        AUTH_ERROR: (state) => {
            state.status = 'error'
        },
        AUTH_LOGOUT: (state) => {
            state.status = '';
                state.token = null;
        },
        START_PROCESSING: (state) =>
        {
            state.processing++;
            console.log("Processing - Start: "+state.processing);
        },
        STOP_PROCESSING: (state) =>
        {
            if(state.processing > 0)
            {
                state.processing--;
            }
            console.log("Processing - Stop: "+state.processing);
        },
        SET_MESSAGES: (state, _messages) => {
            console.log(JSON.stringify(_messages));
            state.messages = _messages;
        },
        SET_ACTION: (state, _value) => {
            console.log("State Mutation: "+ JSON.stringify(_value));
            state.actionName = _value;
        },
        SET_AUTH_TOKEN: (state, _value) => {
            state.token = _value;
            if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
            {
                state.status = 'success';
            }
            else
            {
                state.status = '';
            }
        },
        USER_ID: (state, _value) => {
            state.userId = _value;
        },

        SET_RULE:(state,rule)=>
        {
            state.rule = rule;
        },
        SET_MENU_LINKS:(state,_value)=>
        {
            state.menu_links = _value;
        },
        SET_PAGE_HEADER_HTML:(state,_value)=>
        {
            state.page_header_html = _value;
        },
        SET_TRIGGER_TOOLTIP(state, _value)
        {
            state.trigger_tooltip = _value;
        },

        SET_USER:(state,user)=>
        {
            state.user = user;
        },
        SET_SITE:(state,site)=>
        {
            state.site = site;
        },


        SET_RULES_LIST:(state,rules)=>{
            state.rules = rules;
        },
        SET_SITES_LIST:(state,sites)=>{
            state.sites = sites;
        },
        SET_COUNTRIES_LIST:(state,countries)=>{
            state.countries = countries;
        },
        SET_CITIES_LIST:(state,cities)=>{
            state.cities = cities;
        },
        SET_DISTRICTS_LIST:(state,districts)=>{
            state.districts = districts;
        }
    },
    actions: {

        SET_AUTH_TOKEN:({commit},_value)=>
        {
            commit('SET_AUTH_TOKEN',_value);
        },
        COMMIT_SET_MENU_LINKS:({commit},_value)=>
        {
            commit('SET_MENU_LINKS',_value);
        },
        COMMIT_SET_PAGE_HEADER_HTML:({commit},_value)=>
        {
            commit('SET_PAGE_HEADER_HTML',_value);
        },

        AUTH_REQUEST: ({commit}, user) => {
            return new Promise((resolve) => { // The Promise used for router redirect in login
                commit('AUTH_REQUEST');
                commit('START_PROCESSING');
                http({url: '/login', data: user, method: 'POST', timeout: 30000 })
                    .then(resp =>
                    {
                        commit('STOP_PROCESSING');

                        console.log("Response:"+JSON.stringify(resp));
                        console.log("user:"+JSON.stringify(resp.data.data.user));
                        const token = resp.data.data.token;
                        localStorage.setItem('token', token) ;// store the token in localstorage

                        http.defaults.headers.common['Authorization'] ="Bearer "+ token;
                        commit('USER_ID', resp.data.data.id);


                        commit('AUTH_SUCCESS', token);
                        // you have your token, now log in your user :)
                        //dispatch('USER_REQUEST');
                        resolve(resp)
                    })
                    .catch(response =>
                    {
                        console.log("Error Response: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');

                        commit('SET_MESSAGES', response.data.messages);
                        console.log("MSG"+JSON.stringify(response.data.messages));

                        /*commit('AUTH_ERROR', err);
                        localStorage.removeItem('token') ;// if the request fails, remove any possible user token if possible
                        reject(err)*/
                    })
            })
        },

        AUTH_LOGOUT: ({commit}) => {
            return new Promise((resolve) => {
                commit('AUTH_LOGOUT');
                localStorage.removeItem('token');

                // remove the axios default header
                delete http.defaults.headers.common['Authorization'];
                resolve()
            })
        },
        START_PROCESSING:({commit}) =>
        {
            commit('START_PROCESSING');
        },
        STOP_PROCESSING:({commit}) =>
        {
            commit('STOP_PROCESSING');
        },
        SET_ACTION:({commit},_value)=>
        {
            commit('SET_ACTION',_value);
        },
        GET_MESSAGES:({commit},messages)=>
        {
            console.log("SET MESSAGES TO "+JSON.stringify(messages));
            commit('SET_MESSAGES',messages);
        },
        RESPONSE_PROCESS_AJAX_ERROR : ({commit}, _value) =>
        {
            let _arrMsg = new Array();
            if(typeof _value.data !== "undefined" && _value.data !== null)
            {
                _arrMsg = _value.data.messages;
            }
            else if(typeof _value.message !== "undefined" && _value.message !== null)
            {
                _arrMsg.push(_value.message);
            }
            else
            {
                window.console.log("Unhandled-Error------");
                window.console.log(JSON.stringify(_value));
                _arrMsg.push("Unhandled Error - An error occurred, please try again later.");
            }
            commit("SET_MESSAGES", _arrMsg);
        },


        GET_RULE_REQUEST:({commit},rule)=>
        {
            commit('START_PROCESSING');

            console.log("org: "+rule);
            return new Promise((resolve) => {
                http({
                    url: '/rules/view',
                    method: 'POST',
                    data:{
                        service:rule,
                        source: this.$source
                    },
                         timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("RULE: " + JSON.stringify(resp.data.data));
                    commit('SET_RULE',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        // this.$store.dispatch('GET_MESSAGES',response.data.messages);
                    })
            })
        },

        GET_USER_REQUEST:({commit},user)=>
        {
            commit('START_PROCESSING');

            console.log("org: "+user);
            return new Promise((resolve) => {
                http({
                    url: '/organisations/users/view',
                    method: 'POST',
                    data:{
                        user:user,
                        source: this.$source
                    },
                         timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("User: " + JSON.stringify(resp.data.data));
                    commit('SET_USER',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('SET_MESSAGES',response.data.messages);
                        // this.$store.dispatch('GET_MESSAGES',response.data.messages);
                    })
            })
        },
        GET_SITE_REQUEST:({commit},data)=>
        {
            commit('START_PROCESSING');

            console.log("site: "+JSON.stringify(data));
            return new Promise((resolve) => {
                http({
                    url: '/sites/view',
                    method: 'POST',
                    data:data,
                         timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("Site: " + JSON.stringify(resp.data.data));
                    commit('SET_SITE',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('SET_MESSAGES',response.data.messages);
                        // this.$store.dispatch('GET_MESSAGES',response.data.messages);
                    })
            })
        },


        GET_RULES_REQUEST:({commit})=>
        {
            commit('START_PROCESSING');

            return new Promise((resolve) => {
                http({
                    url: '/rules/list',
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("Rules: " + JSON.stringify(resp.data.data));
                    commit('SET_RULES_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('GET_MESSAGES',response.data.messages);
                    })
            })
        },

        GET_COUNTRIES_REQUEST:({commit})=>
        {
            commit('START_PROCESSING');

            return new Promise((resolve) => {
                http({
                    url: 'countries/list',
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                   // console.log("Response:" + JSON.stringify(resp));
                    //console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                   // console.log("countries: " + JSON.stringify(resp.data.data));
                    commit('SET_COUNTRIES_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        //console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('SET_MESSAGES',response.data.messages);
                    })
            })
        },
        GET_CITIES_REQUEST:({commit})=>
        {
            commit('START_PROCESSING');

            return new Promise((resolve) => {
                http({
                    url: '/cities/list',
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("cities: " + JSON.stringify(resp.data.data));
                    commit('SET_CITIES_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('GET_MESSAGES',response.data.messages);
                    })
            })
        },
        GET_SITES_REQUEST:({commit},organisation)=>
        {
            commit('START_PROCESSING');

            return new Promise((resolve) => {
                http({
                    url: '/sites/list',
                    data:{
                        organisation:organisation,
                        source: "HomeBoy"
                    },
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("Sites: " + JSON.stringify(resp.data.data));
                    commit('SET_SITES_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('GET_MESSAGES',response.data.messages);
                    })
            })
        },

        GET_DISTRICTS_REQUEST:({commit})=>
        {
            commit('START_PROCESSING');

            return new Promise((resolve) => {
                http({
                    url: '/districts/list',
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING');

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    console.log("Districts: " + JSON.stringify(resp.data.data));
                    commit('SET_DISTRICTS_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING');
                        commit('GET_MESSAGES',response.data.messages);
                    })
            })
        },
        AUTH_LOGIN: ({commit}, _token) => {
            commit('AUTH_SUCCESS', _token);
        },
    },
    modules: {
        organisations,
        subscriptions,
        suppliers, auth, countries, foodcredit,
        contracts,packages,services,users,customers,loyaltolocal, thirdparty,registration
    },
    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        isProcessing:state=> state.processing,
    },

    plugins: [createPersistedState({
        key: 'stored_state',
        reducer (val) {
           // console.log("IN reducer: "+JSON.stringify(val));

            if(val.token === null || val.token == 0 || val.status == "") { // val.user.token (your user token for example)

                //console.log("IN reducer: token is null "+val.token);
                return {}
            }
            return val
        }
    })],

});

