import http from "../../http-common";
import moment from "../../assets/js/moment.js"
export default {
    state: {
        customer: {},
        customers: [],
        customerInfo: {},
        country: null,
        customer_selected_service:{},
        msisdn: null,
        validation: {
            msisdn: {
                minLength: 6,
                maxLength: 15
            },
            pin: {
                minLength: 4,
                maxLength: 12
            },
        }

    },

    getters: {
        year_of_birth:()=>{

            const _arrYears = [];
            const today = moment()/*.format("YYYY-MM-DD")*/;

            console.log("Today: "+today);

            const end_year = today.year();

            console.log("This Year: "+end_year);

            const new_end_year = moment(today.subtract(18,"years"));

            console.log("Year - 18: "+new_end_year.year());

            const start_year = moment(today.subtract(100,"years"));

            console.log("Year - 100: "+start_year.year());


            for(let i = new_end_year.year(); i >= start_year.year(); i--)
            {
                //console.log("Year: "+i);
                _arrYears.push({name:i,key:i});
            }

            return _arrYears;

        },
        gender:()=>{
            return [{name:"Female",key:"female"}, {name:"Male",key:"male"}]
        }
    },

    mutations: {
        SET_MSISDN: (state, _value) => {
            state.msisdn = _value;
        },
        SET_COUNTRY: (state, _value) => {
            state.country = _value;
        },
        SET_CUSTOMER: (state, customer) => {
            state.customer = customer;
        },
        SET_CUSTOMER_INFO: (state, info) => {
            state.customerInfo = info;
        },

        SET_CUSTOMER_LIST: (state, customers) => {
            state.customers = customers;
        },
        SET_CUSTOMER_SELECTED_SERVICE: (state, service) => {
            state.customer_selected_service = service;
        }
    },

    actions: {
        CUSTOMER_SET_MSISDN: ({ commit }, _value) => {
            commit("SET_MSISDN", _value);
        },
        CUSTOMER_SET_COUNTRY: ({ commit }, _value) => {
            commit("SET_COUNTRY", _value);
        },
        GET_CUSTOMER_AUTH_REQUEST: ({commit,dispatch}, _formData) => {
            return new Promise((resolve) => { // The Promise used for router redirect in login
                commit('START_PROCESSING',{ root: true });
                http({url: '/customers/login', data: _formData, method: 'POST', timeout: 30000 })
                    .then(resp =>
                    {
                        commit('STOP_PROCESSING',{ root: true });

                        commit("SET_MSISDN", _formData.msisdn);
                        /*commit("SET_COUNTRY", _formData.country);*/

                        console.log("Response:"+JSON.stringify(resp));
                        console.log("customer:"+JSON.stringify(resp.data.data.info));
                        const token = resp.data.data.token;
                        localStorage.setItem('token', token) ;// store the token in localstorage

                        http.defaults.headers.common['Authorization'] ="Bearer "+ token;
                        commit('SET_CUSTOMER_INFO', resp.data.data.info);


                        commit('SET_AUTH_TOKEN', token,{ root: true });
                        // you have your token, now log in your user :)
                        resolve(resp);
                    })
                    .catch(_response =>
                    {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    })
            })
        },
        GET_CUSTOMER_LIST: ({ commit }) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: "/customers/list",
                    method: "POST",
                    timeout: 30000
                })
                    .then(resp => {
                        commit("STOP_PROCESSING", { root: true });

                        console.log("Response:" + JSON.stringify(resp));
                        console.log("data:" + JSON.stringify(resp.data));

                        //this.organisation = resp.data.data;

                        // console.log("Supplier: " + JSON.stringify(resp.data.data));
                        commit("SET_CUSTOMER_LIST", resp.data.data);
                        //this.$router.push('/organisation/view');
                        // you have your token, now log in your user :)

                        resolve(resp);
                    })
                    .catch(response => {
                        console.log("Response Error: " + JSON.stringify(response));
                        commit("STOP_PROCESSING", { root: true });
                        commit("SET_MESSAGES", response.data.messages, { root: true });
                    });
            });
        },
        GET_CUSTOMER_REQUEST: ({ commit }, customer) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: "/customers/view",
                    method: "POST",
                    data: {
                        customer: customer
                    },
                    timeout: 30000
                })
                    .then(resp => {
                        commit("STOP_PROCESSING", { root: true });

                        console.log("Response:" + JSON.stringify(resp));
                        console.log("data:" + JSON.stringify(resp.data));

                        //this.organisation = resp.data.data;

                        // console.log("Supplier: " + JSON.stringify(resp.data.data));
                        commit("SET_CUSTOMER", resp.data.data);
                        //this.$router.push('/organisation/view');
                        // you have your token, now log in your user :)

                        resolve(resp);
                    })
                    .catch(response => {
                        console.log("Response Error: " + JSON.stringify(response));
                        commit("STOP_PROCESSING", { root: true });
                        commit("SET_MESSAGES", response.data.messages, { root: true });
                    });
            });
        },
        SET_CUSTOMER: ({ commit }, customer) => {
            commit("SET_CUSTOMER", customer);
        },
        SET_CUSTOMER_INFO: ({ commit }, customer) => {
            commit("SET_CUSTOMER_INFO", customer);
        },
        CUSTOMER_SELECTED_SERVICE: ({ commit }, service) => {
            commit("SET_CUSTOMER_SELECTED_SERVICE", service);
        },
        CUSTOMER_AUTH_LOGOUT: ({commit,dispatch}) => {
            commit('START_PROCESSING',{ root: true });
            return new Promise((resolve) => {
                http({
                    url: '/customers/logout',
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        localStorage.removeItem('token');
                        commit("SET_MSISDN");
                        commit("SET_COUNTRY");
                        delete http.defaults.headers.common['Authorization'];
                        dispatch('SET_AUTH_TOKEN');
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch('SET_AUTH_TOKEN');
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            })
        },


    }
};
