import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import ProductInfo from "../views/ProductInfo.vue";
import store from "../store/index";

import FirstStep from '../components/form/steps/FirstStep.vue';
import SecondStep from '../components/form/steps/SecondStep.vue';
import ThirdStep from '../components/form/steps/ThirdStep.vue';
import FourthStep from '../components/form/steps/FouthStep.vue';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
    console.log("Not Authed: ");

    console.log("To: "+to.path);
    console.log("From: "+from.path);
    console.log("Next: "+next.path);
    if (!store.getters.isAuthenticated) {
        next();
    }
    else
    {
        next(false)
    }
};

const ifAuthenticated = (to, from, next) => {
    console.log("Authed: ");
    console.log("To: "+to.path);
    console.log("From: "+from.path);
    console.log("Next: "+next.path);

    if (store.getters.isAuthenticated) {
        next();
    }
    else
    {
        next('/')
    }
};

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/privacy-policy",
        name: "Privacy-Policy",
        component: PrivacyPolicy,
    },
    {
        path: "/product-info",
        name: "Product-Info",
        component: ProductInfo,
    },
    //customers

    {
        path:"/customer/home",
        name:"customer-home",
        component: () =>import("../views/customers/Home.vue"),
        beforeEnter:ifAuthenticated
    },
    {
        path:"/view/service",
        name:"customer-view-service",
        component: () =>import("../views/customers/ViewService.vue"),
        beforeEnter:ifAuthenticated
    },
    {
        path: "/default/register",
        component: () =>
            import("../views/customers/DefaultPage.vue"),
        beforeEnter:ifNotAuthenticated
    },
    {
        path:"/redemptions/history",
        name:"customer-redemptions-history",
        component: () =>import("../views/customers/RedemptionsHistory.vue"),
        beforeEnter:ifAuthenticated
    },
    {
        path:"/register",
        name:"customer-register",
        component: () =>import("../views/customers/Register.vue"),
        beforeEnter:ifNotAuthenticated
    },
    {
        path:"/register/supplier",
        name:"supplier-register",
        component: () =>import("../views/RegisterSupplier.vue"),
        beforeEnter:ifNotAuthenticated

        //beforeEnter() {window.location.href = 'https://console.homeboybw.com/'}

        //this.$router.push({ name: "register-supplier", params: {message:"123"}})
    },
    {
        path: '/register/supplier/existing',
        name: 'firstStep',
        component: FirstStep,
        beforeEnter:ifNotAuthenticated
    },
    {
        path: '/register/supplier/existing/second-step',
        name: 'secondStep',
        component: SecondStep,
        beforeEnter:ifNotAuthenticated
    },
    {
        path: '/register/supplier/existing/third-step',
        name: 'thirdStep', component: ThirdStep,
        beforeEnter:ifNotAuthenticated
    },
    {
        path: '/register/supplier/new',
        name: 'fourthStep',
        component: FourthStep,
        beforeEnter:ifNotAuthenticated
    },
    {
        path:"/change/pin",
        name:"customer-change-pin",
        component: () =>import("../views/customers/ChangePin.vue"),
        beforeEnter:ifAuthenticated
    },
    {
        path:"/search/supplier",
        name:"customer-search-supplier",
        component: () =>import("../views/customers/SupplierSearch.vue"),
    },
    {
        path:"/update/profile",
        name:"customer-update-profile",
        component: () =>import("../views/customers/UpdateCustomer.vue"),
        beforeEnter:ifAuthenticated
    },
    {
        path:"/pin/reset/request",
        name:"customer-pin-reset-request",
        component: () =>import("../views/customers/ForgotPin.vue"), beforeEnter:ifNotAuthenticated
    },
    {
        path:"/pin/reset",
        name:"customer-pin-reset",
        component: () =>import("../views/customers/ResetPin.vue"),beforeEnter:ifNotAuthenticated
    },
    {
        path:"/confirm/mobile",
        name:"customer-confirm-mobile",
        component: () =>import("../views/customers/VerifyAccount.vue"),beforeEnter:ifNotAuthenticated

    },
    {
        path:"/send/otp",
        name:"send-otp",
        component: () =>import("../views/customers/SendOtp.vue"),beforeEnter:ifNotAuthenticated
    },
    {
        path:"/login",
        name:"customer-login",
        component: () =>import("../views/customers/Login.vue"),beforeEnter:ifNotAuthenticated
    },
    {
        path: "/logout",
        name: "Logout",
    },
    {
        path:"/transact",
        beforeEnter() {window.location.href = 'https://console.homeboybw.com'}
    },
];

const router = new VueRouter({
    mode: 'hash',
    duplicateNavigationPolicy: 'ignore',
    routes
});

export default router;
